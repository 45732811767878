import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ArticleModel, RimSearchCriteraModel, RimTyresSearchModel } from '@/types/api'
import WheelSearchStep from '@/types/WheelSearchStep'

// Type for our state
export interface WheelsState {
  rimsCriteria?: RimSearchCriteraModel
  tyresCriteria?: RimTyresSearchModel,
  selectedRim?: ArticleModel,
  step: WheelSearchStep
}

// Initial state
const initialState: WheelsState = {
  step: WheelSearchStep.Search,
}

// Actual Slice
export const wheelsSlice = createSlice({
  name: 'wheels',
  initialState,
  reducers: {
    setRimsCriteria: (state, action) => {
      state.rimsCriteria = action.payload
    },
    setTyresCriteria: (state, action) => {
      state.tyresCriteria = action.payload
    },
    setSelectedRim: (state, action: PayloadAction<ArticleModel>) => {
      state.selectedRim = action.payload
    },
    resetSelectedRim: (state) => {
      state.selectedRim = undefined
    },
    setStep: (state, action: PayloadAction<WheelSearchStep>) => {
      state.step = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.search,
      }
    })
  },
})

export const {
  setRimsCriteria,
  setTyresCriteria,
  setSelectedRim,
  resetSelectedRim,
  setStep
} = wheelsSlice.actions

export const selectRimsCriteria = (state: RootState) => state.wheels.rimsCriteria
export const selectTyresCriteria = (state: RootState) => state.wheels.tyresCriteria
export const selectSelectedRim = (state: RootState) => state.wheels.selectedRim
export const selectStep = (state: RootState) => state.wheels.step

export default wheelsSlice.reducer